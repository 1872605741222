var transitionSpeed = 10000;

export const gradients = {
  'default-state': {
    gradients: [
      ['#a117e6', '#a117e6'],
      ['#17b9e6', '#17b9e6'],
    ],
    transitionSpeed,
  },
  'sw-holding': {
    gradients: [
      ['#a117e6', '#a117e6'],
      ['#17b9e6', '#17b9e6'],
    ],
    transitionSpeed,
  },
  'job-consulting': {
    gradients: [
      ['#55c3fa', '#55c3fa'],
      ['#e67417', '#e67417'],
    ],
    transitionSpeed,
  },
  'job-creativ': {
    gradients: [
      ['#8abac8', '#8abac8'],
      ['#e67417', '#e67417'],
    ],
    transitionSpeed,
  },
  psw: {
    gradients: [
      ['#003d51', '#003d51'],
      ['#003d51', '#003d51'],
    ],
    transitionSpeed,
  },
  'strawo-job': {
    gradients: [
      ['#e67417', '#e67417'],
      ['#822622', '#822622'],
    ],
    transitionSpeed,
  },
  drakula: {
    gradients: [
      ['#c10b1b', '#c10b1b'],
      ['#233a63', '#233a63'],
    ],
    transitionSpeed,
  },
  'jc-invest': {
    gradients: [
      ['#c317e6', '#c317e6'],
      ['#e67417', '#e67417'],
    ],
    transitionSpeed,
  },
  'sw-imment': {
    gradients: [
      ['#ff0000', '#ff0000'],
      ['#466ec6', '#466ec6'],
    ],
    transitionSpeed,
  },
  'job-consulting-berlin': {
    gradients: [
      ['#ff0000', '#ff0000'],
      ['#466ec6', '#466ec6'],
    ],
    transitionSpeed,
  },
  'strawo-tech': {
    gradients: [
      ['#ff0000', '#ff0000'],
      ['#466ec6', '#466ec6'],
    ],
    transitionSpeed,
  },
};
