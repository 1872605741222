import $ from 'jquery';

export function handleSubmit() {
  $(document).on('submit', '.contact-form', function (e) {
    e.preventDefault();
    const $this = $(this);
    const message = $('.messages');
    const inputs = $this.find('input, textarea');
    let submit = 0;
    inputs.removeClass('error success');
    message.removeClass('error success');

    inputs.each(function () {
      if(!$(this).val()) {
        message.addClass('error').find('p').text('Bitte füllen Sie alle Felder aus');
        $(this).addClass('error');
      } else {
        submit++;
        $(this).addClass('success');
      }
    })

    if(submit === 4) {
      const target = window.location.hash.replace('#','') || 'default-state';
      const action = `${$this.attr('action')}?target=${target}`;
      const data = $this.serialize();
      console.log(data);
      $.post(action, data, function (response) {
        let res = JSON.parse(response);
        if(res.status === 'error') {
          message.addClass('error').find('p').text(res.text);
          setTimeout(function () {
            message.removeClass('error').find('p').text('');
          }, 5000);
        } else {
          message.addClass('success').find('p').text(res.text);
          inputs.removeClass('error success');
          inputs.val('');
          setTimeout(function () {
            message.removeClass('success').find('p').text('');
          }, 5000);
        }
      })
    }
  })
}
