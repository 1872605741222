// import tns from 'tiny-slider';
const { tns } = require('/node_modules/tiny-slider/src/tiny-slider');

export function initSlider(target) {
  var slider = tns({
    container: '.slider',
    mode: 'gallery',
    controls: false,
    nav: false,
    speed: 2000,
    autoplay: true,
    autoplayTimeout: 8000,
    autoplayButtonOutput: false,
    loop: true,
    touch: false,
  });
}
