const $ = require('jquery');
const Granim = require('granim');
// require('/node_modules/particles.js/particles.js');
const imagesLoaded = require('imagesloaded');

const { handleSubmit } = require('./form');
const { initSlider } = require('./slider');

const { gradients } = require('./config/gradients');
const { particles } = require('./config/particles');
const { browser } = require('./utils/get-browser.js');

var granimInstance = new Granim({
  element: '#gradient-canvas',
  direction: 'left-right',
  elToSetClassOn: '.canvas-container',
  opacity: [1, 1],
  isPausedWhenNotInView: true,
  stateTransitionSpeed: 500,
  image: {
    source: '',
    blendingMode: 'multiply',
  },
  states: gradients,
});

$(document).ready(function () {
  handleSubmit();
  // particlesJS('particle-canvas', particles);

  var homeNav = $('.hexagon');
  var menuButton = $('.menu-button-container');

  homeNav.on('click', navControl);
  menuButton.on('click', function () {
    $(this).addClass('default-state');
    var target = 'default-state';
    assignWindowHash(target);

    $('.modal, nav a').removeClass('active');
    $(`.${target}, nav a[data-target="${target}"]`).addClass('active');

    checkLogoMenu();
    changeBackgroundImage(target);

    initSlider(target);

    granimInstance.changeState(target);
  });

  function navControl() {
    var $this = $(this);
    var target = checkTarget($this.data('target'));

    $('.modal, nav a').removeClass('active');
    $(`.${target}, nav a[data-target="${target}"]`).addClass('active');

    // $('.shape2').css('fill', colors[target]);

    changeBackgroundImage(target);

    assignWindowHash(target);
    checkLogoMenu();
    granimInstance.changeState(target);
  }
});

window.addEventListener('load', function () {
  setTimeout(function () {
    $('.loader-container').fadeOut();
  }, 300);

  if (browser === 'cry-in-a-corner' || browser === 'at-least-something-works') {
    document.querySelector('.menu-button-container').classList.add('ie');
  }

  var target = checkTarget(getUrlHash());
  $(`.${target}, nav a[data-target="${target}"]`).addClass('active');
  // $('.shape2').css('fill', colors[target]);

  checkLogoMenu();
  changeBackgroundImage(target);

  initSlider(target);

  granimInstance.changeState(target);
});

function changeBackgroundImage(target) {
  $('#gradient-canvas').css('opacity', '1');
  setTimeout(function () {
    checkImg();
  }, 300);
  if (checkTargetForBackground(target)) return;
  for (var i = 1; i <= 3; i++) {
    $(`.s${i}`).css('background-image', `url(images/bg-imgs/${target}${i}.jpg)`);
  }
}

function checkImg() {
  imagesLoaded('.slide', { background: true }, function (instance) {
    $('#gradient-canvas').css('opacity', '0.3');
  });
}

let prevBackgroundTarget = '';
function checkTargetForBackground(target) {
  return prevBackgroundTarget === target;
}

function checkLogoMenu() {
  var target = checkTarget(getUrlHash());
  var menuButton = $('.menu-button-container');

  if (target === 'default-state') {
    menuButton.addClass('default-state');
  } else {
    menuButton.removeClass('default-state');
  }
}

function getUrlHash() {
  return window.location.hash.replace('#', '') || 'default-state';
}

function checkTarget(target) {
  if (target === 'home') {
    return 'default-state';
  } else {
    return target;
  }
}

function assignWindowHash(target) {
  if (target === 'default-state') {
    window.location.assign(`#home`);
  } else {
    window.location.assign(`#${target}`);
  }
}
